<template>
    <section class="section">
        <form @submit.prevent="fetch" class="row align-items-end">
            <div class="col-8">
                <label>対象期間</label>
                <div class="input-group">
                    <input v-model="condition.listing_date_from" type="date" class="form-control">
                    <div class="input-group-text">〜</div>
                    <input v-model="condition.listing_date_to" type="date" class="form-control">
                </div>
            </div>
            <div class="col-4">
                <button type="submit" class="btn btn-primary btn-full">表示</button>
            </div>
            <div class="col-8">
                <button type="submit" class="btn" @click="this.condition.listing_date_from = this.this_month_from; this.condition.listing_date_to = this.this_month_to">今月</button>
                <button type="submit" class="btn" @click="this.condition.listing_date_from = this.last_month_from; this.condition.listing_date_to = this.last_month_to">先月</button>
                <button type="submit" class="btn" @click="this.condition.listing_date_from = this.latest_month_from; this.condition.listing_date_to = this.latest_month_to">直近1ヶ月</button>
                <button type="submit" class="btn" @click="this.condition.listing_date_from = this.latest_week_from; this.condition.listing_date_to = this.latest_week_to">直近1週間</button>
            </div>
        </form>
    </section>

    <section class="section">
        <div class="alert alert-secondary" v-if="stats.length === 0">データがありません</div>
        <table class="table" v-else>
            <thead class="table-primary">
                <tr>
                    <th>ユーザ</th>
                    <th>新規出品</th>
                    <th>追加出品</th>
                    <th>再出品</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="row of stats" :key="row.user_id">
                    <td>{{ row.user_name }}</td>
                    <td>{{ row.new_count }}</td>
                    <td>{{ row.add_count }}</td>
                    <td>{{ row.re_count }}</td>
                </tr>
            </tbody>
        </table>
    </section>

</template>


<script>
export default {
    name: 'PageStats',
    components: {

    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage'
    ],
    data() {
        return {
            condition: {
                listing_date_from: null,
                listing_date_to: null,
            },
            this_month_from: null,
            this_month_to: null,
            last_month_from: null,
            last_month_to: null,
            latest_month_from: null,
            latest_month_to: null,
            latest_week_from: null,
            latest_week_to: null,
            stats: [],
        }
    },
    mounted() {
        this.setUpDates();

        this.condition.listing_date_from = this.this_month_from;
        this.condition.listing_date_to = this.this_month_to;

        this.fetch();
    },
    methods: {
        fetch() {
            this.startScreenLoading();
            this.$http.get('/summary/listing', {params: this.condition})
            .then(response => {
                this.stats = response.data;
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        setUpDates() {
            let from, to;

            from = new Date();
            from.setDate(1);
            this.this_month_from = this.$helper.ymdForInputDate(from);

            to = new Date();
            to.setDate(1);
            to.setMonth(to.getMonth()+1);
            to.setDate(0);
            this.this_month_to = this.$helper.ymdForInputDate(to);

            from = new Date();
            from.setDate(1);
            from.setMonth(from.getMonth()-1);
            this.last_month_from = this.$helper.ymdForInputDate(from);

            to = new Date();
            to.setDate(0);
            this.last_month_to = this.$helper.ymdForInputDate(to);

            from = new Date();
            from.setDate(from.getDate()-31);
            this.latest_month_from = this.$helper.ymdForInputDate(from);

            to = new Date();
            to.setDate(to.getDate()-1);
            this.latest_month_to = this.$helper.ymdForInputDate(to);

            from = new Date();
            from.setDate(from.getDate()-8);
            this.latest_week_from = this.$helper.ymdForInputDate(from);

            to = new Date();
            to.setDate(to.getDate()-1);
            this.latest_week_to = this.$helper.ymdForInputDate(to);
        }
    }
}
</script>
